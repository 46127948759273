/*
 * custom-theme-color1.less
 * -----------------------------------------------
*/
.feature-item .features-description {
  background: fade(@theme-color, 80%) ;
}
.team-thumb h4,
.project-gallery .project-thumb::before,
.form-transparent select option {
  background: fade(@theme-color, 90%) ;
}
.icon-box.icon-filled i {
	background: @theme-color;
}