/*
 * background: theme-color
 * -----------------------------------------------
*/
.post .entry-meta.meta-absolute {
  background-color: fade(@theme-color, 90%) !important;
}
.tab-slider .nav.nav-pills a:hover,
.tab-slider .nav.nav-pills a.active,
.custom-nav-tabs > li > a:hover,
.widget .tags a:hover,
.small-title .title::after,
.drop-caps.colored-square p:first-child:first-letter,
.drop-caps.colored-rounded p:first-child:first-letter,
.working-process.theme-colored a,
.blog-posts .post .entry-content .post-date.right,
.horizontal-tab-centered .nav-pills > li > a:hover,
.horizontal-tab-centered .nav-pills > li.active > a,
.horizontal-tab-centered .nav-pills > li.active  > a:hover,
.horizontal-tab-centered .nav-pills > li.active > a:focus,
.owl-theme.dot-theme-colored .owl-controls .owl-dot span,
.portfolio-filter a.active, .portfolio-filter a:hover,
.pagination.theme-colored li.active a,
.section-title .both-side-line::after,
.section-title .both-side-line::before,
.section-title .top-side-line::after,
.section-title .left-side-line::before,
.section-title .right-side-line::before,
.product .tag-sale,
.blog-posts .post.style1 .entry-header .entry-date.entry-date-absolute,
.title-dots span {
  background: @theme-color;
}
.cd-timeline-simple .cd-timeline-block .cd-timeline-img {
    background: @theme-color !important;
}
.ui-state-highlight {
  background: @theme-color !important;
  color: @white-base !important;
}
.menuzord.default .menuzord-menu > li.active > a, 
.menuzord.default .menuzord-menu > li:hover > a, 
.menuzord.default .menuzord-menu ul.dropdown li:hover > a {
  background: @theme-color2;
  color: #fff;
}
.menuzord.theme-colored .menuzord-menu > li.active > a, 
.menuzord.theme-colored .menuzord-menu > li:hover > a, 
.menuzord.theme-colored .menuzord-menu ul.dropdown li:hover > a {
  background: @theme-color2;
  color: #fff;
}
.gallery-isotope.default-animation-effect .gallery-item .overlay-shade {
  background: @theme-color;
}
.search-menu {
	background: @theme-color2;
}
/*
 * text: theme-color
 * -----------------------------------------------
*/
.menuzord-menu > li > .megamenu .megamenu-row li:hover > a,
.menuzord-menu > li > .megamenu .megamenu-row .post a:hover,
.menuzord-menu > li > .megamenu .megamenu-row li:hover > a i,
.menuzord-menu > li > .megamenu .megamenu-row .post a:hover i,
.blog-posts .post .entry-meta li i,
.widget .address li i,
.icon-box.box-style1.practice-style3 i,
.icon-box.services-style1:hover .heading,
.horizontal-contact-widget .widget::before,
.small-title i,
.drop-caps.text-colored p:first-child:first-letter,
.schedule-box:hover .schedule-details .title a,
.widget.dark .nav-tabs li.active a,
.pagination.theme-colored li a,
.pager.theme-colored a,
.widget .post-title a:hover,
.donation-form .form-group label {
  color: @theme-color;
}
/*
 * border: theme-color
 * -----------------------------------------------
*/
.pager.theme-colored a,
.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-prev, 
.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-next {
  border: 1px solid @theme-color;
}
.owl-theme .owl-dots .owl-dot span{
  border-color: @theme-color;
}
.owl-carousel.owl-nav-bottom .owl-nav button i {
	color: @theme-color2;
}
.owl-carousel.owl-nav-top .owl-nav button{
	background: @theme-color2 !important;
}
.owl-carousel.owl-nav-bottom .owl-nav .owl-prev,
.owl-carousel.owl-nav-bottom .owl-nav .owl-next {
  border: 1px solid @theme-color2 !important;
}
.owl-carousel.owl-nav-top .owl-nav .owl-prev,
.owl-carousel.owl-nav-top .owl-nav .owl-next {
  border: 1px solid @theme-color2 !important;
}
.icon-box.box-style1.practice-style3:hover .icon-wrapper::after {
  border-color: @theme-color transparent transparent;
}

.section-title.title-border {
    border-left: 4px solid @theme-color;
}

/* border-top-color */
.cssload-tri {
  border-top: 27px solid @theme-color;
}
.border-top-theme-colored2-2px {
  border-top: 2px solid @theme-color2;
}
.border-top-theme-colored2-5px {
  border-top: 5px solid @theme-color2;
}
.border-hover-theme-colored:hover {
  border: 1px solid @theme-color2 !important;
}
/* border-right-color */
.border-right-red {
  border-right: 1px solid @theme-color;
}
.section-title.title-border.title-right {
    border-right: 4px solid @theme-color;
}

/* border-bottom-color */
.cssload-tri.cssload-invert {
  border-bottom: 27px solid @theme-color;
}
.team-members.style2:hover .team-bottom-part {
  border-color: @theme-color2 !important;
}
/* box-shadow */



/*Footer Styles*/
.footer-box-one > div {
  background: @theme-color;
}
.footer-box-two > div {
  background: darken(@theme-color,5%) !important;
}
.footer-box-three > div {
  background: darken(@theme-color,10%) !important;
}
